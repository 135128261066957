table.overview th:nth-child(3) {
  display: none;
}
table.overview td:nth-child(3) {
  display: none;
}
table.overview td:nth-child(7) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.overview th:nth-child(7) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.overview th:nth-child(11) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.overview td:nth-child(11) {
  border-right: 4px solid rgb(237, 237, 237);
}

table.adult th:nth-child(2) {
  display: none;
}
table.adult td:nth-child(2) {
  display: none;
}
table.adult td:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.adult th:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}

table.pediatric th:nth-child(2) {
  display: none;
}
table.pediatric td:nth-child(2) {
  display: none;
}
table.pediatric td:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.pediatric th:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.pediatric td:nth-child(10) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.pediatric th:nth-child(10) {
  border-right: 4px solid rgb(237, 237, 237);
}

table.specialty th:nth-child(2) {
  display: none;
}
table.specialty td:nth-child(2) {
  display: none;
}
table.specialty td:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.specialty th:nth-child(6) {
  border-right: 4px solid rgb(237, 237, 237);
}

table.mombaby th:nth-child(2) {
  display: none;
}
table.mombaby td:nth-child(2) {
  display: none;
}
table.mombaby td:nth-child(5) {
  border-right: 4px solid rgb(237, 237, 237);
}
table.mombaby th:nth-child(5) {
  border-right: 4px solid rgb(237, 237, 237);
}
.tableWrap {
  display: block;
  height: 600px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: auto;
}

.tableWrap-tab {
  display: block;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
  width: auto;
  height: 800px;
}
.dropdown_customized .rw-widget-input {
  min-width: 300px;
  max-width: 920px;
 
  font-size: 12px;
  margin-top: 3px;
}
.dropdown_customized .rw-popup-container {
  margin-top: -20px;
}
.dropdown_customized_2 .rw-popup-container {
 
  z-index: 100;
}