@import url("https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: #eee;
  font-family: "Ubuntu", sans-serif;
}

.box-container {
  position: relative;
  display: flex;
  flex-direction:row;
  width: 100%;
  flex-wrap: wrap;
  
}

.box {
  position: relative;
  width: 350px;
  margin: 20px;
  height: 168px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 0 25px 0 rgba(20, 27, 202, 0.06);
}

h3 {
  padding: 38px;
  font-size: 20px;
}

@media (max-width: 480px) {

  .box {
    position: relative;
    width: 250px;
    height: 100px;

  }
  h3 {
    font-size: small;
    padding: 5px;
  }

}
@media (min-width: 481px) and (max-width: 767px) {

  .box {
    position: relative;
    width: 250px;
    height: 100px;
  }

  h3 {
    font-size: small;
    padding: 5px;
  }
}


.box:hover h3,
.box:hover p {
  color: #fff;
}

.wrapper:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.01);
  cursor: pointer;
  background-image: linear-gradient(
    -45deg,
    rgb(52, 181, 191) 30%,
    rgb(6, 82, 92) 100%
  );
  color: #eee;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
