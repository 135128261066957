table {
  border-spacing: 0;
  font-size: smaller;
  border: 1px solid #ededed;
}

.tabs:hover {
  background-color: #33b3bd;
  color: white;
}
.occupancyBox {
  background-color: white;
  position: sticky;
  top: 124px;
  z-index: 3;
  padding: 6px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}
.occupancyBox1 {
  background-color: white;
  position: sticky;
  top: 64px;
  z-index: 3;
  padding: 9px 0px 6px 0px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0px 8px 6px -12px black;
}
.sticky-thc-totals {
  position: sticky;
  background-color: white;
  top: 70px;
  z-index: 2;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}
#sticky-thc {
  position: sticky;
  top: 265px;
  z-index: 2;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -12px black;
}
table tr:last-child td {
  border-bottom: 0;
}

table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
}
table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}

table th::before {
  position: relative;
  right: 15px;
  top: 16px;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
table th.sort-asc::before {
  border-bottom: 5px solid #22543d;
}
table th.sort-desc::before {
  border-top: 5px solid #22543d;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.badge-container {
  background-color: #ffffff;
  margin-right: 4px;
  width: 100;
  border-radius: 20%;
  border: 0.5px solid rgb(94, 94, 94);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.badge {
  background-color: #9ae6b4;
  float: left;
  color: #22543d;
  margin-right: 4px;
  width: 50;
  border-radius: 20%;
  border: 0.5px solid rgb(94, 94, 94);
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.badgeTotalNumber {
  background-color: #9ae6b4;
  color: #22543d;
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 10%;
  border: 0.5px solid rgb(94, 94, 94);
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
}
input {
  padding: 5px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: none;
}

.legend-box {
  padding: 10px;
  float: inline-end;
  border-radius: 2px;
  -webkit-box-shadow: 0 10px 10px 10px rgba(69, 90, 100, 0.08);
  box-shadow: 0px 10px 10px 0px rgba(69, 90, 100, 0.08);
  border: none;
}
.legend-labels {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-right: 3px;
}

#circle1 {
  width: 1px;
  height: 1px;
  background: #ff7f7f;
  border-radius: 50%;
}
#circle2 {
  background: #ff7f7f;
  float: left;
  height: 18px;
  width: 18px;
  border-radius: 30%;
  border: 0.5px solid rgb(94, 94, 94);
  clear: both;
}
#circle3 {
  background: #f2e34c;
  float: left;
  height: 18px;
  width: 18px;
  border-radius: 30%;
  border: 0.5px solid rgb(94, 94, 94);
  clear: both;
}
#circle4 {
  background: #9ae6b4;
  float: left;
  height: 18px;
  width: 18px;
  border-radius: 30%;
  border: 0.5px solid rgb(94, 94, 94);
  clear: both;
}

.legend-labels > div {
  padding-right: 20px;
  padding-left: 20px;
}

.card-title {
  color: #035854;
}
#new-tooltip span {
  visibility: hidden;
  width: auto;

  background-color: rgb(68, 65, 65);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 10%;
  margin-bottom: 10px;

  opacity: 0.9;
}
#new-tooltip:hover span {
  visibility: visible;
}
