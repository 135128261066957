table {
    border-spacing: 0;
    border: 0px solid #ededed;
  }
  .tableWrap {
    display: block;
    height: 600px;
    overflow-y:scroll;
    overflow-x: scroll;
    width: auto;
  
  }
  
  .tableWrap-tab {
    display: block;
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    width: auto;
    height: 800px;
  }
  
  table#sticky-thc thead {
    position: sticky;
    top: 63.5px;
    z-index: 2;
    padding: 10px;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -12px black;
  }
  table tr:last-child td {
    border-bottom: 0;
  }
  
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
  }
  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  table th::before {
    position: relative;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid #7ae4b2;
  }
  table th.sort-desc::before {
    border-top: 5px solid #22543d;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }
  
th:nth-child(1){
    border-right: 1px solid rgb(237,237,237) ;
    }
    
    th:nth-child(5){
      border-right: 1px solid rgb(237,237,237) ;
      }
      th:nth-child(9){
        border-right: 1px solid rgb(237,237,237) ;
        }